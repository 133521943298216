import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './productSlider.module.scss';
import { Divider, Image, Link, Text } from '@/components/atoms';
import Slider from '@/components/molecules/Slider';
import { ProductGalleryCard } from '@/components/molecules';
import useDeviceType from '@/hooks/useDeviceType';
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const storeId = process.env.NEXT_PUBLIC_STORE_ID;

const ProductSlider = (props) => {
  const {
    title,
    items,
    openModalOnClick = false,
    withLinkToGallery = '',
    titleTag = 'h3',
    withDots = false,
    withoutChevrons = false,
    banner = {},
    withDivider = false,
    cartPage = false
  } = props;

  const { isDesktop } = useDeviceType();

  const titleClasses = cn({
    [styles.title]: true,
    [styles.withLink]: withLinkToGallery,
    [styles.cartPage]: cartPage
  });
  const containerClasses = cn({
    [styles.container]: true,
    [styles.withBanner]: banner.url,
    [styles.cartPage]: cartPage
  });
  return (
    <div
      className={containerClasses}
      style={{ display: banner?.url && isDesktop ? 'flex' : 'block' }}
    >
      {banner.url ? (
        <Link
          style={{ maxWidth: '205px' }}
          href={banner.url}
          linkStyle={{
            width: '100%',
            height: isDesktop ? '92%' : '100%'
          }}
        >
          <Image
            imageStyles={{ width: '100%', height: '100%' }}
            src={
              isDesktop ? banner?.desktop_image?.url : banner?.mobile_image?.url
            }
            alt={`banner${banner?.alt_text}`}
            width={isDesktop ? 768 : 100}
            height={isDesktop ? 350 : 200}
            heightSkeleton={isDesktop ? '261.1px' : '400px'}
          />
        </Link>
      ) : (
        <div className={titleClasses}>
          <Text
            textColor="var(--color-white-absolute)"
            textStyle={{
              textAlign: 'center',
              marginBottom: '10px'
            }}
            variant={titleTag}
            textSize="l"
          >
            {title}
          </Text>
          {withDivider && <Divider />}
          {/* {!isDesktop && !withLinkToGallery && <Divider />} */}
          {withLinkToGallery && (
            <Link
              href={`/productos/o/${withLinkToGallery}/p/1`}
              primary
              linkStyle={{
                textDecoration: storeId === 'city' ? 'underline' : 'none',
                textDecorationColor: 'var(--color-primary)'
              }}
            >
              Ver todo
            </Link>
          )}
        </div>
      )}
      {banner?.url && <div style={{ height: '20px', width: '20px' }} />}
      <div style={{ width: banner?.url && isDesktop ? '85%' : '100%' }}>
        <Slider
          hightlight
          withDots={withDots}
          withoutChevrons={withoutChevrons}
        >
          {items.map((i) => (
            <ProductGalleryCard
              key={i.id}
              prefetch
              productId={i.id}
              featured={i.featured}
              Link
              productUrl={`${i.url}`}
              openModalOnClick={openModalOnClick}
              isFavorite={i.is_wish_product}
              imageSrc={i.dummy_images[0].url}
              cardContent={{
                gender: i.gender,
                installments: `${i.all_prices.installments.quantity} x $${
                  i.all_prices.installments.value.split(',')[0]
                } s/int.`,
                price: i.all_prices.sale_price
                  ? i.all_prices.sale_price
                  : i.all_prices.original,
                strikePrice: i.all_prices.sale_price
                  ? i.all_prices.original
                  : '',
                name: i.name,
                discount: i.all_prices.discount,
                brandIcon: i.brand_icon,
                brandName: i.brand_name
              }}
              text="Creemos en tu espíritu ganador. Descubrí acá esos productos running ideales para vos."
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

ProductSlider.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  openModalOnClick: PropTypes.bool,
  withLinkToGallery: PropTypes.string,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'span']),
  withDots: PropTypes.bool,
  withoutChevrons: PropTypes.bool,
  banner: PropTypes.object,
  withDivider: PropTypes.bool,
  cartPage: PropTypes.bool
};

export default ProductSlider;
